<template>
  <div class="grant-mobile">
   <UPButton
           @click="btnClick"
           :scope="scope"
           :style="{width:'20rem',backgroundColor:'red'}"
           >
        授权获取手机号
</UPButton>

  </div>
</template>

<script>
import { Button } from 'vant';
import { setToken, setTokenByMinute, getTokenByMinute } from '@/utils/auth'
import { getBackendToken, getAccessToken } from "@/api/login";
import { getMobile } from "@/api/user";
import { parseTime } from "@/utils/common";




export default {
    name: 'GrantMobile',
    components: {
      [Button.name]: Button
    },
    data() {
      return {
        // 手机号授权域
        scope: "scope.mobile",

        // 获取accessToken和openId
        tokenParams: {
          backendToken: '',
          code: ''
        },

        // 获取用户手机号
        mobileParams: {
          accessToken: '',
          openId: '',
          backendToken: ''
        },

        // 添加云闪付用户
        addParams: {
          openId: '',
          accessToken: '',
          accessTokenTime: '',
          cellphone: '',
          username: ''
        },
      }
    },
   created() {
        this.onInit();
   },
    methods: {
      // 得到过期时间
      getExpiresTime(expires) {
        let time = new Date();
        time.setSeconds(time.getSeconds() + expires);
        return parseTime(time, "{y}-{m}-{d} {h}:{i}:{s}")
      },

      // 初始化
      onInit() {
        // 设置Header
        let appId = "0001"; //this.$route.query && this.$route.query.appId;
        setToken(appId);

        //if(appId === undefined) {
           //appId = "0001";
        //}

        // 获取BackendToken
        //let backendToken = getTokenByMinute();
        //if(backendToken) {
            //this.tokenParams.backendToken = backendToken;
            //this.mobileParams.backendToken = backendToken;
            //console.log("getTokenByMinute[backendToken]");
            //return true;
        //}
        getBackendToken().then(response => {
            if(response.code === 200) {
                this.tokenParams.backendToken = response.data.backendToken;
                this.mobileParams.backendToken = response.data.backendToken;
                setTokenByMinute(response.data.backendToken, response.data.expiresIn);
                console.log("setTokenByMinute[backendToken]");
                console.log(getTokenByMinute());
            }
        });
      },
      btnClick(event, err, result) {
        //console.log(event, err, result)
        //console.log(err); //null
         console.log(result); //undefined
         if(result !== undefined) {
            console.log(result.code);
            this.tokenParams.code = result.code;

            // 获取accessToken和openId
            getAccessToken(this.tokenParams).then(response => {
                 console.log(response);
                 if(response.code === 200) {
                    this.mobileParams.openId = response.data.openId;
                    this.mobileParams.accessToken = response.data.accessToken;

                    this.addParams.openId = response.data.openId;
                    this.addParams.accessToken = response.data.accessToken;
                    this.addParams.accessTokenTime = this.getExpiresTime(response.data.expiresIn);

                    console.log(this.addParams);

                    getMobile(this.mobileParams).then(response => {
                        console.log("getMobile=");
                        console.log(response);
                        alert("手机号" + response.data.mobile);
                        // 添加云闪付用户
                        this.addParams.cellphone = response.data.mobile;
                    });

                 }
            });
         }

         //{code: "xGJxRyxkDP6mm65QEDBMr=pW"}
      }
    }
  }
</script>
