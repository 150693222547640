import request from '@/utils/request'

// 获取登录手机号
export function getMobile(data) {
    return request({
        url: 'open/union/1.0/user/mobile',
        method: 'post',
        data: data
      })
}
// 获取用户证件信息
export function getAuth(data) {
    return request({
        url: 'open/union/1.0/user/auth',
        method: 'post',
        data: data
      })
}
// 获取用户状态
export function getStatus(data) {
    return request({
        url: 'open/union/1.0/user/status',
        method: 'post',
        data: data
      })
}
// 添加云闪付用户
export function addUser(data) {
    return request({
        url: 'open/union/1.0/user/save',
        method: 'post',
        data: data
      })
}

